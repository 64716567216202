import React from "react"
import InnerHeader from "../../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/seo"
import * as rcifStyles from "./rcif-scholarship.module.scss"
import scholarshipPdf from '../../../../assets/images/about-us/rcif/rcif_scholarship_2024.pdf' 

const RCIFScholarship = ({ pageContext, location }) => {
  const pageTitle = "RCIF Scholarship"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />

      <div className={rcifStyles.wrapper}>
        {/* Hero Grid */}
        <section className={rcifStyles.heroGrid}>
          <div className={rcifStyles.copyAndImage}>
            {/* HERO IMAGE */}
            <div className={rcifStyles.heroImage}>
              <img
                src={
                  require("../../../../assets/images/about-us/rcif/rcif-circle-scholarship.png")
                    .default
                }
                alt=""
              />
            </div>
            {/* LOGO AND ANCHOR */}
            <div className={rcifStyles.copyAndAnchor}>
              <h2>2025 Scholarships</h2>
              <h3>ELIGIBILITY CRITERIA</h3>
              <ul>
                <li>
                  <p data-typesense-field="description">GPA of 3.0 or better</p>
                </li>
                <li>
                  <p data-typesense-field="description">Demonstrated good character in service, leadership, and dedication to your school and community</p>
                </li>
                <li>
                  <p data-typesense-field="description">Must be a senior at a surrounding high school<br/>(Nelsonville-York, Trimble, Logan, Federal Hocking, Athens, or Alexander)</p>
                </li>
              </ul>
              <h3>APPLY BY 3/28/25</h3>
              <div>
                {/* <a
                  href={scholarshipPdf}
                  className={rcifStyles.ctaScholarship}
                  title="Anchor to Download Scholarship Application PDF"
                  download
                >
                  Download Application
                </a> */}
              </div>
            </div>
          </div>
        </section>
        {/* END HERO GRID */}

        <section className={rcifStyles.scholarshipForm}>
          <form
            name="rcif-scholarship"
            data-netlify="true"
            method="POST"
            netlify-honeypot="special-request"
            enctype="multipart/form-data"
          >
            <input class="hidden" name="special-request" />
            <input type="hidden" name="form-name" value="rcif-scholarship" />
            <small>
              <span
                className="text-danger"
                style={{ fontSize: `1.1rem`, fontWeight: `bold` }}
              >
                *
              </span>{" "}
              indicates required field
            </small>

            <div className="form-group">
              <label htmlFor="high-school">
                High school attended <span className="text-danger">*</span>
              </label>
              <select name="high-school" id="high-school">
                <option value="" selected="selected">
                  Choose school
                </option>
                <option value="alexander">Alexander</option>
                <option value="athens">Athens</option>
                <option value="fed-hock">Federal Hocking</option>
                <option value="logan">Logan</option>
                <option value="nelsonville-york">Nelsonville-York</option>
                <option value="trimble">Trimble</option> 
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="graduation-year">
                Graduation year <span className="text-danger">*</span>
                <input
                  type="number"
                  className="form-control"
                  id="graduation-year"
                  name="graduation-year"
                  autoComplete="graduation-year"
                  required
                  maxLength={4}
                />
              </label>
            </div>

            {/* name */}
            <div className="form-group">
              <label htmlFor="name">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                autoComplete="name"
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="phone">
                Phone <span className="text-danger">*</span>
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                autoComplete="tel"
                required
              />
            </div>

            {/* address */}
            <div className="form-group">
              <label htmlFor="address-line-1">
                Address <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="address-line-1"
                name="address-line-1"
                required
              />
              <small>Address line 1</small>
            </div>

            <div className="form-group">
              <input
                type="text"
                id="address-line-2"
                name="address-line-2"
              />
              <label
                style={{ fontWeight: `normal` }}
                htmlFor="address-line-2"
              >
                <small>Address line 2</small>
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="college-university">
                College or university you plan to attend <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="college-university"
                name="college-university"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="fafsa-contribution">
                Estimated family contribution from your FAFSA SAR <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="fafsa-contribution"
                name="fafsa-contribution"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="household-members">
                Number of household members{" "}
                <span className="text-danger">*</span>
                <input
                  type="number"
                  id="household-members"
                  name="household-members"
                  required
                  maxLength={2}
                />
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="household-attending">
                Number attending college this year{" "}
                <span className="text-danger">*</span>
                <input
                  type="number"
                  id="household-attending"
                  name="household-attending"
                  required
                  maxLength={2}
                />
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="goal-plan">
                Career plans and goals: <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="goal-plan"
                name="goal-plan"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="extracurricular-activities">
                Extracurricular activities: <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="extracurricular-activities"
                name="extracurricular-activities"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="academic-achievements">
                Academic achievements (scholarships, honors, awards): <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="academic-achievements"
                name="academic-achievements"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="service-volunteer">
                Service/Volunteer activities (church/community): <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="service-volunteer"
                name="service-volunteer"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="work-experience">
                Work/Internship experience: <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="work-experience"
                name="work-experience"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="positive-impact">
                How will this scholarship positively impact your college experience? <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="positive-impact"
                name="positive-impact"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="adversity">
                Give an example of when you overcame adversity: <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="adversity"
                name="adversity"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="community-impact">
                How has growing up in your community impacted your life? <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="community-impact"
                name="community-impact"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="special-circumstance">
                Special circumstances to be considered: <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="special-circumstance"
                name="special-circumstance"
                rows="3"
                maxLength={750}
                required
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="transcript"
                className={rcifStyles.fileUploadText}
              >
                Upload Transcript
              </label>
              <input
                type="file"
                className="file-upload"
                id="transcript"
                name="transcript"
                placeholder="Upload Application"
                required
              />
            </div>

            <div>
              <input className="CTAblack" type="submit" value="Submit" />
            </div>
          </form>
        </section>
      </div>
    </Layout>
  )
}

export default RCIFScholarship

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">RCIF Scholarship</title>
    <meta
      name="description"
      content="To provide impactful support to our local communities by awarding grants to build a clean, safe, and healthy community for our neighbors."
    />
  </>
)
